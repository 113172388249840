// // src/components/MainServicesPage/PlayServicesPage.js

import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { projectFirestore } from "../../firebase/config";
import { UserContext } from "../contexts/UserContext";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Spinner,
  Button,
} from "react-bootstrap";
import backgroundImage from "../../assets/livingroom3.jpg";
import styles from "./MainServicesPage.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

const PlayServicesPage = () => {
  const { currentUser, loading } = useContext(UserContext);
  const [wins, setWins] = useState([]);
  const [totalWins, setTotalWins] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [todayWinners, setTodayWinners] = useState([]); // Array to store multiple winners
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;

    if (!currentUser.isPlayer || !currentUser.profileCompleted) {
      alert(
        "Please complete your profile to ensure we have all the necessary details to initiate payment once you claim your winnings. This step is essential for securing your transactions and expediting the payment process. Thank you for your cooperation!"
      );
      navigate("/profile-completion");
    } else {
      fetchData();
    }
  }, [currentUser, navigate]);

  const fetchData = async () => {
    fetchWins();
    fetchReferrals();
    fetchTodayWinners(); // Fetch multiple winners for today
  };

  // Fetch today's winners from daily, weekly, and monthly collections
  const fetchTodayWinners = async () => {
    try {
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);

      const collections = ["dailyWinners", "weeklyWinners", "monthlyWinners"];
      const winnerPromises = collections.map((coll) =>
        getDocs(
          query(
            collection(projectFirestore, coll),
            where("date", ">=", Timestamp.fromDate(todayStart)),
            where("date", "<=", Timestamp.fromDate(todayEnd))
          )
        )
      );

      const snapshots = await Promise.all(winnerPromises);
      const winners = snapshots
        .flatMap((snapshot) =>
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        )
        .sort((a, b) => a.date.seconds - b.date.seconds); // Sort winners by time

      setTodayWinners(winners); // Store all winners for today
    } catch (error) {
      console.error("Error fetching today's winners:", error);
    }
  };

  const fetchWins = async () => {
    const queries = ["dailyWinners", "weeklyWinners", "monthlyWinners"].map(
      (coll) =>
        query(
          collection(projectFirestore, coll),
          where("userId", "==", currentUser.uid)
        )
    );
    try {
      const snapshots = await Promise.all(queries.map(getDocs));
      const allWins = snapshots.flatMap((snap) =>
        snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
      setWins(allWins);
      setTotalWins(allWins.reduce((sum, win) => sum + win.amount, 0));
    } catch (error) {
      console.error("Error fetching wins:", error);
    }
  };

  const fetchReferrals = async () => {
    try {
      const referralsQuery = query(
        collection(projectFirestore, "referrals"),
        where("referredBy", "==", currentUser.uid)
      );
      const referralsSnapshot = await getDocs(referralsQuery);
      setReferrals(
        referralsSnapshot.docs.map((doc) => ({
          id: doc.id,
          displayName: doc.data().displayName,
          referralDate: doc
            .data()
            .referralDate?.toDate()
            .toLocaleDateString("en-GB"),
          signedUp: doc.data().signedUp ? "Yes" : "No",
          signedUpDate: doc
            .data()
            .signedUpDate?.toDate()
            .toLocaleDateString("en-GB"),
          earnedAmount: doc.data().earnedAmount,
        }))
      );
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  const handleClaimWin = async (win) => {
    const collectionName =
      win.type === "daily"
        ? "dailyWinners"
        : win.type === "weekly"
        ? "weeklyWinners"
        : "monthlyWinners";

    const winRef = doc(projectFirestore, collectionName, win.id);
    const claimedTimestamp = Timestamp.now();

    try {
      await updateDoc(winRef, {
        claimed: true,
        claimedDate: claimedTimestamp,
        documentID: win.id,
      });

      setWins(
        wins.map((w) =>
          w.id === win.id
            ? { ...w, claimed: true, claimedDate: claimedTimestamp.toDate() }
            : w
        )
      );

      alert(
        "Your payment is being processed, you will receive payment within 24 hours."
      );
    } catch (error) {
      console.error("Error claiming win:", error);
      alert("Failed to claim the win. Please try again later.");
    }
  };

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  if (loading || !currentUser) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <section>
      <div
        className={styles.hero}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className={styles.content}>
          <h1>Welcome to AllGhanaians</h1>
          <p>
            Join our daily, weekly, and monthly giveouts to win your Kenkey Gift
            Prizes! Refer your friends to increase your chances of winning.
          </p>
          {!loading && !currentUser && (
            <p className="text-warning">
              Please sign up using the navigation bar above to participate in
              the giveouts.
            </p>
          )}
          <Link to="/winners" className="btn btn-secondary">
            Go to Play Dashboard
          </Link>
        </div>
      </div>

      <Container className="mt-5">
        <Row className="mb-4">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title className="text-center">
                  Welcome, {currentUser.displayName}
                </Card.Title>
                {todayWinners.length > 0 ? (
                  todayWinners.map((winner, index) => (
                    <Card.Text key={winner.id} className="text-center">
                      {winner.userId === currentUser.uid
                        ? `Congratulations, ${
                            currentUser.displayName
                          }! You won the ${
                            index % 2 === 0 ? "06:00 AM" : "12:00 PM"
                          } ${
                            winner.type.charAt(0).toUpperCase() +
                            winner.type.slice(1)
                          } Kenkey Gift prize of ${winner.amount} cedis.`
                        : `The ${index % 2 === 0 ? "06:00 AM" : "12:00 PM"} ${
                            winner.type.charAt(0).toUpperCase() +
                            winner.type.slice(1)
                          } Kenkey Gift prize winner is ${
                            winner.name
                          }, who won ${winner.amount} cedis.`}
                    </Card.Text>
                  ))
                ) : (
                  <Card.Text className="text-center">
                    No winners yet for today. Check back later!
                  </Card.Text>
                )}
                <p className="text-center mt-3">
                  Winners must claim their winnings to receive payment.
                  Unclaimed winnings cannot be paid out.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Your Wins</Card.Title>
                <div className="table-responsive">
                  <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>WinID</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Paid</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wins.map((win) => (
                        <tr key={win.id}>
                          <td>{formatDate(win.date.seconds)}</td>
                          <td>{win.id}</td>
                          <td>{win.amount} cedis</td>
                          <td>{win.type}</td>
                          <td>{win.claimed ? "Claimed" : "Not Claimed"}</td>
                          <td>{win.paid}</td>
                          <td>
                            <Button
                              variant="primary"
                              disabled={win.claimed}
                              onClick={() => handleClaimWin(win)}
                            >
                              {win.claimed ? "Claimed" : "Claim"}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <Card.Text className="mt-3">
                  Total Wins: <strong>{totalWins} cedis</strong>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <p>
                  Earn referral income by having friends and family also
                  participate in Kenkey Gift Prize and enjoy future
                  allghanaians.com services coming soon!
                </p>
                <Card.Title>Your Referrals</Card.Title>
                <Table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Referral Date</th>
                      <th>Signed Up</th>
                      <th>Signed Date</th>
                      <th>Earned Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referrals.map((referral) => (
                      <tr key={referral.id}>
                        <td>{referral.displayName}</td>
                        <td>{referral.referralDate}</td>
                        <td>{referral.signedUp}</td>
                        <td>{referral.signedUpDate}</td>
                        <td>{referral.earnedAmount} cedis</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PlayServicesPage;

