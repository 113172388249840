//src/components/Payments/FlutterwavePayments

import { useState, useContext, useEffect } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useOrder } from "../contexts/OrderContext";
import { useUser } from "../contexts/UserContext"; // Import useUser hook
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

import { projectFirestore } from "../../firebase/config";

export default function FlutterwavePayments({
  calculatedPrice,
  selectedServiceDetails,
  formData,
  setBookingConfirmation,
  resetForm,
}) {
  const { currentUser } = useUser(); // Use the useUser hook
  const numericAmount = parseFloat(calculatedPrice);
  const selectedServiceName = selectedServiceDetails?.name;
  // Ensure amount is a number before using toFixed
  const displayAmount = isNaN(numericAmount)
    ? "0.00"
    : numericAmount.toFixed(2);
  const { orderDetails, updateOrderDetails } = useOrder();

  // Set default values from user context
  const [name, setName] = useState(currentUser?.displayName || "");
  const [phoneNumber, setPhone] = useState(currentUser?.phoneNumber || "");
  const [email, setEmail] = useState(currentUser?.email || "");

  useEffect(() => {
    console.log("User data in context:", currentUser);
    if (currentUser) {
      setName(currentUser.displayName || "");
      setPhone(currentUser.phoneNumber || "");
      setEmail(currentUser.email || "");
    }
  }, [currentUser]);

  // const filterServiceDetailsForSelectedService = (formData, selectedServiceName) => {
  //     const relevantFields = getRelevantFieldsForService(selectedServiceName);
  //     return Object.keys(formData)
  //       .filter(key => relevantFields.includes(key))
  //       .reduce((obj, key) => {
  //         obj[key] = formData[key];
  //         return obj;
  //       }, {});
  //   };

  // // console.log("selectedServiceName is:", selectedServiceName)

  // // Implement this function based on your business logic
  // const getRelevantFieldsForService = (selectedServiceName) => {
  //     // Return an array of relevant field names for the selected service
  //     const relevantFieldsForServices = {
  //         'Complete House Cleaning': ['bedrooms', 'bathrooms', 'homeSize', 'frequency', 'additionalInfo'],
  //         'Kitchen Cleaning': ['kitchens', 'additionalInfo', 'frequency'],
  //         'Dusting': ['itemsToDust', 'additionalInfo', 'frequency'],
  //         'Fridge Cleaning': ['fridges', 'additionalInfo', 'frequency'],
  //         'Oven Cleaning': ['ovens', 'additionalInfo', 'frequency'],
  //         'Bathroom Cleaning': ['bathrooms', 'additionalInfo', 'frequency'],
  //         'Cabinets Cleaning': ['cabinets', 'additionalInfo', 'frequency'],
  //         'Deck or Garage Sweeping': ['decksOrGarages', 'additionalInfo', 'frequency'],
  //         'Any Other Cleaning': ['itemsToClean', 'additionalInfo', 'frequency'],
  //         'Tailored Cleaning Service': ['tailoredItems', 'specificRequests', 'frequency'],
  //         'Hard Floor Cleaning': ['hardFloors', 'additionalInfo', 'frequency'],
  //         'Carpet Vacuuming': ['carpets', 'additionalInfo', 'frequency']
  //         // ... add other services and their relevant fields
  //     };

  //     return relevantFieldsForServices[selectedServiceName] || [];
  // };

  const onPaymentSuccess = async (response) => {
    console.log(response);
    // Update order details after successful payment
    updateOrderDetails({ paymentInfo: { ...response, paymentStatus: "Paid" } });

    // Filter serviceDetails to include only relevant fields
    // const filteredServiceDetails = filterServiceDetailsForSelectedService(formData, selectedServiceName);

    // Prepare data for Firestore
    const orderData = {
      customerInfo: {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        uid: currentUser.uid, // Save the user's UID here
      },
      // serviceDetails: orderDetails,
      // serviceDetails: filteredServiceDetails,
      serviceDetails: {
        // ...filteredServiceDetails,
        selectedService: selectedServiceName, // add this if you want to save the name of the service
        calculatedPrice: calculatedPrice, // add this if you want to save the calculated price
        //serviceDate: formData.serviceDate,
        //serviceTime: formData.serviceTime,
      },
      paymentInfo: {
        amount: calculatedPrice, // Use the calculated price
        currency: "GHS",
        transactionId: response.transaction_id, // or the correct key from the response
        paymentStatus: "Paid",
      },
      orderStatus: "Pending",
      createdAt: serverTimestamp(),
    };
    console.log("Order Data being submitted: ", orderData);

    try {
      const docRef = await addDoc(
        collection(projectFirestore, "cleaningOrders"),
        orderData
      );
      console.log("Document written with ID: ", docRef.id);

      // Reset order details after successful order creation
      updateOrderDetails({}); // Resetting the order details in context

      // Set a state in QuoteTool to show confirmation message
      setBookingConfirmation(true); // This state should be managed in QuoteTool

      // Reset other form-related states if needed
      resetForm(); // Ensure this function resets all necessary states

      // Redirect or show confirmation as needed
    } catch (e) {
      console.error("Error adding document: ", e);
      // Handle errors (e.g., show error message to the user)
    }

    closePaymentModal();
  };

  // Config for Flutterwave
  const config = {
    public_key: "FLWPUBK_TEST-fca134bfcf214dc9f3ef2182b0dfd7fd-X",
    tx_ref: Date.now(),
    amount: calculatedPrice,
    currency: "GHS",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: email,
      phoneNumber: phoneNumber,
      name: name,
    },
    customizations: {
      title: "my Payment Title",
      description: "Payment for items in cart",
      logo: "https://link-to-your-logo.jpg",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  // Check if user is not loaded
  if (!currentUser) {
    return <div>Loading...</div>; // Or handle this scenario appropriately
  }

  return (
    <div className="container py-4">
      <div className="card p-4">
        <h2 className="mb-3">Payment Details</h2>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Phone Number</label>
          <input
            type="tel"
            className="form-control"
            value={phoneNumber || ""}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <button
          className="btn btn-primary w-100"
          onClick={() =>
            handleFlutterPayment({
              callback: (response) => {
                console.log(response);
                onPaymentSuccess(response);
                closePaymentModal();
              },
              onClose: () => {},
            })
          }
        >
          Pay GHS {displayAmount}
        </button>
      </div>
    </div>
  );
}



// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
// import { functions } from "firebase/app"; // Import Firebase functions
// import { httpsCallable } from "firebase/functions"; // Import HTTPS callable

// // Reference your initiatePayment function
// const initiatePaymentFunction = httpsCallable(functions, "initiatePayment");

// const initiatePayment = async () => {
//   const paymentData = {
//     amount: calculatedPrice,
//     currency: "GHS",
//     email: email,
//     phoneNumber: phoneNumber,
//     name: name,
//     tx_ref: "txn_" + Date.now(),
//     payment_options: "card,mobilemoney,ussd",
//   };

//   try {
//     const result = await initiatePaymentFunction(paymentData);
//     if (result.data.link) {
//       handleFlutterPayment({
//         callback: (response) => onPaymentSuccess(response),
//         onClose: () => console.log("Payment modal closed"),
//       });
//     }
//   } catch (error) {
//     console.error("Error initiating payment:", error);
//   }
// };
