import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserProvider } from "./components/contexts/UserContext";
// import { UserContext } from './components/contexts/UserContext';
import { OrderProvider } from "./components/contexts/OrderContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/Header/Header";
import HeroSection from "./components/HeroSection/HeroSection";
import ServicesSection from "./components/ServiceSection/ServicesSection";
import QuoteTool from "./components/QuoteTool/QuoteTool";
import OrderIntake from "./components/QuoteTool/OrderIntake";
import Footer from "./components/Footer/Footer";

import Dashboard from "./components/Dashboard/Dashboard";
import DashboardLayout from "./components/Dashboard/DashboardLayout";
import PlayDashboard from "./components/PlayWinners/PlayDashboard/PlayDashboard";

import UserDashboard from "./components/UserDashboard/UserDashboard";
import PartnerRegistration from "./components/Admin/PartnerRegistration/PartnerRegistration";
import PartnerProfile from "./components/Admin/AllServices/PartnerProfile";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import AdminOrderDetails from "./components/AdminDashboard/AdminOrderDetails";
import UserProfile from "./components/UserProfiles/UserProfile";
import AddBooking from "./components/AddBooking/AddBooking";
import AddService from "./components/Admin/AddService/AddService";
import UserOrders from "./components/UserOrders/UserOrders";
import UserOrderDetails from "./components/UserOrders/UserOrderDetails";
import CleaningOrdersList from "./components/CleaningOrdersList/CleaningOrdersList";
import AllServices from "./components/Admin/AddService/AllServices";
import BookingComponent from "./components/BookingComponent";
import PaymentResponseComponent from "./components/PaymentResponseComponent";
import FlutterwavePayments from "./components/Payments/FlutterwavePayments";

// Authentication Imports
import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import ProfileCompletion from "./components/Signup/ProfileCompletion";
import ProtectedRoute from "./components/Signup/ProtectedRoute";
import ServiceDetail from "./components/ServiceSection/ServiceDetail";
import FAQs from "./components/FAQs/Faqs";
import FaqEditor from "./components/FAQs/FaqEditor";
import FaqsList from "./components/FAQs/FaqsList";
import Contacts from "./components/Contacts/Contacts";
import Policies from "./components/Policies/Policies";

// Admin Imports
import AdminPanel from "./components/Admin/AdminPanel/AdminPanel";
import AdminInterface from "./components/Admin/AdminInterface/AdminInterface";

// Adverts Imports
import AdvertForm from "./components/Adverts/AdvertsForms/AdvertForm";
import AdvertsList from "./components/Adverts/AdvertList";
import AdvertsHomePage from "./components/Adverts/AdvertsHomePage";
import CategoryManager from "./components/Adverts/Category/CategoryManager";
import AdvertTypePricesManager from "./components/Admin/Adverts/AdvertTypePricesManager";
// import SubCategoryAdvertsList from './components/Adverts/Category/TOBEDELETEDSubCategoryAdvertsList';
import SubCategoryList from "./components/Adverts/Category/SubCategoryList";
import ProvidedServices from "./components/services/ProvidedServices";
import MainServicesPage from "./components/MainServicesPage/MainServicesPage";
import PlayServicesPage from "./components/MainServicesPage/PlayServicesPage";
import SubCategoryPostPage from "./components/Adverts/Category/SubCategoryPostPage";
import AdvertPostDetailPage from "./components/Adverts/AdvertPostDetailPage";
import PolicyEditor from "./components/Policies/PolicyEditor";
import PolicyList from "./components/Policies/PolicyList";
import AnnouncementDetail from "./components/Adverts/DetailPages/AnnouncementDetail";
import CelebrationDetail from "./components/Adverts/DetailPages/CelebrationDetail";
import BusinessAnnouncementDetail from "./components/Adverts/DetailPages/BusinessAnnouncementDetail";
import CommunityPublicInterestDetail from "./components/Adverts/DetailPages/CommunityPublicInterestDetail";
import AcademicEducationalDetail from "./components/Adverts/DetailPages/AcademicEducationalDetail";
import MiscellaneousDetail from "./components/Adverts/DetailPages/MiscellaneousDetail";

import FormsConfigurationsForm from "./components/Adverts/AdvertsForms/FormsConfigurationsForm";
import FormsConfigurationsList from "./components/Adverts/AdvertsForms/FormsConfigurationsList";
import CategoryConfigurationMappingForm from "./components/Adverts/AdvertsForms/CategoryConfigurationMappingForm";
import CategoryConfigurationList from "./components/Adverts/AdvertsForms/CategoryConfigurationList";
import AnnouncementsForm from "./components/Adverts/AdvertsForms/AnnouncementsForm";
// import AnnouncementsPage from './components/Adverts/AdvertsForms/AnnouncementsPage';

import DynamicDetailPage from "./components/Adverts/DetailPages/DynamicDetailPage";

import MasterDetailInterface from "./components/Adverts/Category/CategoryManagement/MasterDetailInterface";
import ExpandableListInterface from "./components/Adverts/Category/CategoryManagement/ExpandableListInterface";

// AllGDelivery Services
import DeliveryHome from "./components/AllGDeliveries/DeliveryHome";
import AddressManager from "./components/ShippingAddress/AddressManager";
import DeliveryHelp from "./components/AllGDeliveries/DeliveryHelp";
import Sendings from "./components/AllGDeliveries/Sendings/Sendings";
import DeliveryForm from "./components/AllGDeliveries/Packages/DeliveryForm";
import MyOrders from "./components/AllGDeliveries/MyOrders";
import Receivals from "./components/AllGDeliveries/Receivals/Receivals";

// import AdminDeliveryCategories from './components/Admin/AllGDeliveriesAdmin/AdminDeliveryCategories';
import AdminDeliveryCategories from "./components/Admin/AllGDeliveries/AdminDeliveryCategories";
import DeliveryOrderSummary from "./components/AllGDeliveries/Sendings/DeliveryOrderSummary";

import AdvertsOrderSummary from "./components/Adverts/AdvertsOrderSummary";
import DeliveryPartnerOnboarding from "./components/Admin/AllGDeliveries/DeliveryPartnerOnboarding";
import TransportManager from "./components/AllGDeliveries/TransportTypes/TransportManager";
import DeliveryPartnerAdminManager from "./components/Admin/DeliveryPartnerAdminManager";
import DeliveryPartnerAdminManagerList from "./components/Admin/DeliveryPartnerAdminManagerList";
import PostAdvertCategorySelector from "./components/Adverts/AdvertsForms/PostAdvertCategorySelector";
import EmailTest from "./components/Emails/EmailTest";
import TestGame from "./components/PlayWinners/TestGame";
import UserList from "./components/Accounts/UserDetails/UserList";
import UserDetails from "./components/Accounts/UserDetails/UserDetails";
import Notifications from "./components/MessageNotifications/Notifications";
import WebNotifications from "./components/MessageNotifications/WebNotifications";
import TestSendGrid from "./components/Emails/TestSendGrid";
import SendEmailForm from "./components/Emails/SendEmailForm";
import Participation from "./components/Login/Participation";
import WinnerOverviews from "./components/Dashboard/WinnerOverviews";
import AdminWinnerOverviews from "./components/Admin/AdminWinnerOverviews";
import AdminClaimedWins from "./components/AdminDashboard/AdminClaimedWins";

import FunctionsTester from "./components/Test/FunctionsTester";
import MonitoringDashboard from "./components/Payments/MonitoringDashboard";
import BalanceManager from "./components/Payments/BalanceManager";

function App() {
  return (
    <Router>
      <AppInner />
    </Router>
  );
}

function AppInner() {
  const navigate = useNavigate();

  return (
    // <Router>
    <UserProvider navigate={navigate}>
      <OrderProvider>
        <div className="App">
          <Header />

          <Routes>
            {/* Non ProtectedRoutes */}
            <Route path="/" element={<Navigate to="/mainpage" replace />} />
            <Route path="/mainpage" element={<PlayServicesPage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/faqs-list" element={<FaqsList />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/cleaningServices/:id" element={<ServiceDetail />} />
            <Route
              path="/partner-registration"
              element={<PartnerRegistration />}
            />
            <Route
              path="/payment-response"
              element={<PaymentResponseComponent />}
            />
            <Route path="/advert-homepage" element={<AdvertsHomePage />} />
            <Route path="/celebration-detail" element={<CelebrationDetail />} />
            <Route
              path="/business-announcement-detail"
              element={<BusinessAnnouncementDetail />}
            />
            <Route
              path="/community-public-interest-detail"
              element={<CommunityPublicInterestDetail />}
            />
            <Route
              path="/miscellaneous-detail"
              element={<MiscellaneousDetail />}
            />
            <Route
              path="/academic-educational-detail"
              element={<AcademicEducationalDetail />}
            />
            <Route path="/policy-list" element={<PolicyList />} />
            <Route
              path="/advert-details/:advertId"
              element={<AdvertPostDetailPage />}
            />
            <Route
              path="/announcement-detail"
              element={<AnnouncementDetail />}
            />
            {/* Admin Routes */}
            <Route
              path="/admin-dashboard"
              element={
                <ProtectedRoute component={AdminDashboard} roles={["admin"]} />
              }
            />
            <Route
              path="/partnerprofile"
              element={
                <ProtectedRoute component={PartnerProfile} roles={["admin"]} />
              }
            />
            <Route
              path="/adminpanel"
              element={
                <ProtectedRoute component={AdminPanel} roles={["admin"]} />
              }
            />
            <Route
              path="/admin-interface"
              element={
                <ProtectedRoute component={AdminInterface} roles={["admin"]} />
              }
            />
            {/* Both User and AdminRoutes */}
            <Route
              path="/user-profile"
              element={
                <ProtectedRoute
                  component={UserProfile}
                  roles={["user", "admin"]}
                />
              }
            />
            <Route
              path="/cateory-admin"
              element={
                <ProtectedRoute
                  component={AdminDeliveryCategories}
                  roles={["admin"]}
                />
              }
            />
            {/* ProtectedRoutes */}
            <Route
              path="/profile-completion"
              element={<ProtectedRoute component={ProfileCompletion} />}
            />
            <Route
              path="/dashboard"
              element={<ProtectedRoute component={DashboardLayout} />}
            />
            <Route
              path="/claimedwins"
              element={<ProtectedRoute component={AdminClaimedWins} />}
            />

            <Route
              path="/winners-dashboard"
              element={<ProtectedRoute component={PlayDashboard} />}
            />
            <Route
              path="/testgame"
              element={<ProtectedRoute component={TestGame} />}
            />
            <Route
              path="/orders/:orderId"
              element={<ProtectedRoute component={AdminOrderDetails} />}
            />
            <Route
              path="/add-booking"
              element={<ProtectedRoute component={AddBooking} />}
            />
            <Route
              path="/user-orders"
              element={<ProtectedRoute component={UserOrders} />}
            />
            <Route
              path="/order-details/:orderId"
              element={<ProtectedRoute component={UserOrderDetails} />}
            />
            <Route
              path="/list-bookings"
              element={<ProtectedRoute component={CleaningOrdersList} />}
            />
            <Route
              path="/add-service"
              element={<ProtectedRoute component={AddService} />}
            />
            <Route
              path="/all-services"
              element={<ProtectedRoute component={AllServices} />}
            />
            <Route
              path="/create-faq"
              element={<ProtectedRoute component={FaqEditor} />}
            />
            <Route
              path="/edit-faq/:faqId"
              element={<ProtectedRoute component={FaqEditor} />}
            />
            <Route
              path="/booknow"
              element={<ProtectedRoute component={OrderIntake} />}
            />
            <Route
              path="/flutterwavepayments"
              element={<ProtectedRoute component={FlutterwavePayments} />}
            />
            <Route
              path="/advert-form"
              element={<ProtectedRoute component={AdvertForm} />}
            />
            <Route
              path="/edit-advert/:advertId"
              element={<ProtectedRoute component={AdvertForm} />}
            />
            <Route
              path="/my-adverts"
              element={<ProtectedRoute component={AdvertsList} />}
            />
            <Route
              path="/category-manager"
              element={<ProtectedRoute component={CategoryManager} />}
            />
            <Route
              path="/category-master-detail"
              element={<ProtectedRoute component={MasterDetailInterface} />}
            />
            <Route
              path="/category-expandable-list"
              element={<ProtectedRoute component={ExpandableListInterface} />}
            />
            <Route
              path="/provided-services"
              element={<ProtectedRoute component={ProvidedServices} />}
            />
            <Route
              path="/sub-categories-list/:categoryId"
              element={<ProtectedRoute component={SubCategoryList} />}
            />
            <Route
              path="/sub-category-posts/:categoryId/:subCategoryId/:subCategoryName"
              element={<ProtectedRoute component={SubCategoryPostPage} />}
            />
            <Route
              path="/edit-policy/:policyId"
              element={<ProtectedRoute component={PolicyEditor} />}
            />
            <Route
              path="/create-policy"
              element={<ProtectedRoute component={PolicyEditor} />}
            />
            <Route
              path="/categoryonfigurationlist"
              element={<ProtectedRoute component={CategoryConfigurationList} />}
            />
            <Route
              path="/forms-configurations-list"
              element={<ProtectedRoute component={FormsConfigurationsList} />}
            />
            <Route
              path="/forms-configurations-form/:docId"
              element={<ProtectedRoute component={FormsConfigurationsForm} />}
            />
            <Route
              path="/forms-configurations-form"
              element={<ProtectedRoute component={FormsConfigurationsForm} />}
            />
            <Route
              path="/category-configuration-mapping-form"
              element={
                <ProtectedRoute component={CategoryConfigurationMappingForm} />
              }
            />
            <Route
              path="/category-configuration-mapping/:mappingId"
              element={
                <ProtectedRoute component={CategoryConfigurationMappingForm} />
              }
            />
            <Route
              path="/post-announcement/:categoryId/:subCategoryId"
              element={<ProtectedRoute component={AnnouncementsForm} />}
            />
            <Route
              path="/announcements/details/:announcementId"
              element={<ProtectedRoute component={DynamicDetailPage} />}
            />
            <Route
              path="/post-adverts"
              element={
                <ProtectedRoute component={PostAdvertCategorySelector} />
              }
            />
            <Route
              path="/delivery-home"
              element={<ProtectedRoute component={DeliveryHome} />}
            />
            <Route
              path="/address-manager"
              element={<ProtectedRoute component={AddressManager} />}
            />
            <Route
              path="/deliveryhelp"
              element={<ProtectedRoute component={DeliveryHelp} />}
            />
            <Route
              path="/sendings"
              element={<ProtectedRoute component={Sendings} />}
            />
            <Route
              path="/delivery-form"
              element={<ProtectedRoute component={DeliveryForm} />}
            />
            <Route
              path="/delivery-order-summary"
              element={<ProtectedRoute component={DeliveryOrderSummary} />}
            />
            <Route
              path="/myorders"
              element={<ProtectedRoute component={MyOrders} />}
            />
            <Route
              path="/receivals"
              element={<ProtectedRoute component={Receivals} />}
            />
            <Route
              path="/advert-prices-manager"
              element={<ProtectedRoute component={AdvertTypePricesManager} />}
            />
            <Route
              path="/adverts-order-summary"
              element={<ProtectedRoute component={AdvertsOrderSummary} />}
            />
            <Route
              path="/delivery-partner-onboarding"
              element={<ProtectedRoute component={DeliveryPartnerOnboarding} />}
            />
            <Route
              path="/edit-delivery-partner/:partnerId"
              element={<ProtectedRoute component={DeliveryPartnerOnboarding} />}
            />
            <Route
              path="/transport-manager"
              element={<ProtectedRoute component={TransportManager} />}
            />
            <Route
              path="/delivery-partner-admin-manager"
              element={
                <ProtectedRoute component={DeliveryPartnerAdminManager} />
              }
            />
            <Route
              path="/delivery-partner-admin-manager-list"
              element={
                <ProtectedRoute component={DeliveryPartnerAdminManagerList} />
              }
            />
            <Route
              path="/email-test"
              element={<ProtectedRoute component={EmailTest} />}
            />
            <Route
              path="/send-grid"
              element={<ProtectedRoute component={TestSendGrid} />}
            />
            <Route
              path="/send-email"
              element={<ProtectedRoute component={SendEmailForm} />}
            />
            <Route
              path="/users"
              element={<ProtectedRoute component={UserList} />}
            />
            <Route
              path="/user/:id"
              element={<ProtectedRoute component={UserDetails} />}
            />
            <Route
              path="/participation"
              element={<ProtectedRoute component={Participation} />}
            />
            <Route
              path="/notifications"
              element={<ProtectedRoute component={Notifications} />}
            />
            <Route
              path="/winners"
              element={<ProtectedRoute component={WinnerOverviews} />}
            />
            <Route
              path="/monitoring"
              element={<ProtectedRoute component={MonitoringDashboard} />}
            />
            <Route
              path="/balance"
              element={<ProtectedRoute component={BalanceManager} />}
            />
            <Route
              path="/admin-winner-overviews"
              element={
                <ProtectedRoute
                  component={AdminWinnerOverviews}
                  roles={["admin"]}
                />
              }
            />
            {/* Add FunctionsTester Route - Only for Development */}
            {process.env.NODE_ENV === "development" && (
              <Route path="/test-functions" element={<FunctionsTester />} />
            )}
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Footer />
        </div>
      </OrderProvider>
    </UserProvider>
    // </Router>
  );
}

function Home() {
  return (
    <>
      <HeroSection />
      <ServicesSection />
      <WebNotifications />
      {/* <QuoteTool /> */}
    </>
  );
}

export default App;
