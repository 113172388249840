import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { projectFirestore } from "../../firebase/config";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Spinner,
  Button,
  Form,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

const AdminWinnerOverviews = () => {
  const [wins, setWins] = useState([]);
  const [displayedWins, setDisplayedWins] = useState([]); // For displayed wins after filtering
  const [loading, setLoading] = useState(true);
  const [claimStatusFilter, setClaimStatusFilter] = useState("");
  const [paymentStatusFilter, setPaymentStatusFilter] = useState("");
  const [claimDateFilter, setClaimDateFilter] = useState(null);

  useEffect(() => {
    fetchAllWins();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [claimStatusFilter, paymentStatusFilter, claimDateFilter, wins]);

  const fetchAllWins = async () => {
    setLoading(true);
    try {
      const snapshot = await getDocs(
        collection(projectFirestore, "dailyWinners")
      );
      const fetchedWins = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWins(fetchedWins);
      setDisplayedWins(fetchedWins); // Initialize displayedWins with all fetched wins
    } catch (error) {
      console.error("Error fetching wins:", error);
    }
    setLoading(false);
  };

  const applyFilters = () => {
    let filteredWins = [...wins];
    if (claimStatusFilter) {
      filteredWins = filteredWins.filter(
        (win) => win.claimed.toString() === claimStatusFilter
      );
    }
    if (paymentStatusFilter) {
      filteredWins = filteredWins.filter(
        (win) => win.paid === paymentStatusFilter
      );
    }
    if (claimDateFilter) {
      const startOfDay = new Date(claimDateFilter.setHours(0, 0, 0, 0));
      const endOfDay = new Date(claimDateFilter.setHours(23, 59, 59, 999));
      filteredWins = filteredWins.filter(
        (win) =>
          new Date(win.claimedDate.seconds * 1000) >= startOfDay &&
          new Date(win.claimedDate.seconds * 1000) <= endOfDay
      );
    }
    setDisplayedWins(filteredWins);
  };

  // const updatePaymentStatus = async (winId, newStatus) => {
  //   const winRef = doc(projectFirestore, "dailyWinners", winId);
  //   await updateDoc(winRef, {
  //     paid: newStatus,
  //   });
  //   setDisplayedWins(
  //     displayedWins.map((win) =>
  //       win.id === winId ? { ...win, paid: newStatus } : win
  //     )
  //   );
  // };

  const updatePaymentStatus = async (winId, newStatus) => {
    const winRef = doc(projectFirestore, "dailyWinners", winId);
    try {
      await updateDoc(winRef, { paid: newStatus });
      setDisplayedWins(
        displayedWins.map((win) => {
          if (win.id === winId) {
            return { ...win, paid: newStatus };
          }
          return win;
        })
      );
      // Optionally, display a success message to the user
    } catch (error) {
      console.error("Error updating payment status:", error);
      // Optionally, display an error message to the user
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title className="text-center">
                Admin Winner Overview
              </Card.Title>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>User ID</th>
                    <th>Winning ID</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Email</th>
                    <th>Momo</th>
                    <th>Momo Srv Provider</th>
                    <th>Win Type</th>
                    <th>
                      <Form.Select
                        value={claimStatusFilter}
                        onChange={(e) => setClaimStatusFilter(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="true">Claimed</option>
                        <option value="false">Unclaimed</option>
                      </Form.Select>
                    </th>
                    <th>
                      <DatePicker
                        selected={claimDateFilter}
                        onChange={(date) => setClaimDateFilter(date)}
                        dateFormat="MMMM d, yyyy"
                        isClearable
                        placeholderText="Select a date"
                        className="form-control"
                      />
                    </th>
                    <th>
                      <Form.Select
                        value={paymentStatusFilter}
                        onChange={(e) => setPaymentStatusFilter(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="Yes Paid">Yes Paid</option>
                        <option value="Not Paid">Not Paid</option>
                      </Form.Select>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayedWins.map((win) => (
                    <tr key={win.id}>
                      <td>{formatDate(win.date.seconds)}</td>
                      <td>{win.userId}</td>
                      <td>{win.id}</td>
                      <td>{win.name}</td>
                      <td>{`${win.amount} cedis`}</td>
                      <td>{win.email}</td>
                      <td>{win.momoNumber}</td>
                      <td>{win.momoServiceProvider}</td>

                      <td>{win.type}</td>
                      <td style={{ color: win.claimed ? "blue" : "black" }}>
                        {win.claimed ? "Claimed" : "Unclaimed"}
                      </td>
                      <td>
                        {win.claimedDate
                          ? formatDate(win.claimedDate.seconds)
                          : "N/A"}
                      </td>
                      <td
                        style={{
                          color: win.paid === "Yes Paid" ? "blue" : "black",
                        }}
                      >
                        {win.paid || "Not Paid"}
                        {win.paid !== "Yes Paid" && (
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() =>
                              updatePaymentStatus(win.id, "Yes Paid")
                            }
                          >
                            Mark as Paid
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminWinnerOverviews;
