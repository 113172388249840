// src/components/Dashboard/WinnerOverviews.js

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { projectFirestore } from "../../firebase/config";
import { UserContext } from "../contexts/UserContext";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Spinner,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const WinnerOverviews = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [wins, setWins] = useState([]);
  const [totalWins, setTotalWins] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [todayWinner, setTodayWinner] = useState(null);
  const [showToast, setShowToast] = useState(false); // For displaying the toast
  const [toastMessage, setToastMessage] = useState(""); // Message inside the toast
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;

    // Check if user is a player and if the profile is completed
    if (!currentUser.isPlayer || !currentUser.profileCompleted) {
      alert(
        "Please complete your profile to ensure we have all the necessary details to initiate payment once you claim your winnings. This step is essential for securing your transactions and expediting the payment process. Thank you for your cooperation!."
      );
      navigate("/profile-completion");
    } else {
      // Fetch all necessary data since the user is fully qualified
      fetchData();
    }
  }, [currentUser, navigate]);

  const fetchData = async () => {
    fetchWins();
    fetchReferrals();
    fetchTodayWinner();
  };

  const fetchWins = async () => {
    const queries = ["dailyWinners", "weeklyWinners", "monthlyWinners"].map(
      (coll) =>
        query(
          collection(projectFirestore, coll),
          where("userId", "==", currentUser.uid)
        )
    );
    try {
      const snapshots = await Promise.all(queries.map(getDocs));
      const allWins = snapshots.flatMap((snap) =>
        snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
      setWins(allWins);
      setTotalWins(allWins.reduce((sum, win) => sum + win.amount, 0));
    } catch (error) {
      console.error("Error fetching wins:", error);
    }
  };

  const fetchReferrals = async () => {
    try {
      const referralsQuery = query(
        collection(projectFirestore, "referrals"),
        where("referredBy", "==", currentUser.uid)
      );
      const referralsSnapshot = await getDocs(referralsQuery);
      setReferrals(
        referralsSnapshot.docs.map((doc) => ({
          id: doc.id,
          displayName: doc.data().displayName,
          referralDate: doc
            .data()
            .referralDate?.toDate()
            .toLocaleDateString("en-GB"),
          signedUp: doc.data().signedUp ? "Yes" : "No",
          signedUpDate: doc
            .data()
            .signedUpDate?.toDate()
            .toLocaleDateString("en-GB"),
          earnedAmount: doc.data().earnedAmount,
        }))
      );
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  const fetchTodayWinner = async () => {
    try {
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999);

      const todayWinnerQuery = query(
        collection(projectFirestore, "dailyWinners"),
        where("date", ">=", Timestamp.fromDate(todayStart)),
        where("date", "<=", Timestamp.fromDate(todayEnd))
      );
      const snapshot = await getDocs(todayWinnerQuery);
      if (!snapshot.empty) setTodayWinner(snapshot.docs[0].data());
    } catch (error) {
      console.error("Error fetching today's winner:", error);
    }
  };

  const handleClaimWin = async (win) => {
    const collectionName =
      win.type === "daily"
        ? "dailyWinners"
        : win.type === "weekly"
        ? "weeklyWinners"
        : "monthlyWinners";

    const winRef = doc(projectFirestore, collectionName, win.id);
    const claimedTimestamp = Timestamp.now(); // Get the current timestamp

    try {
      await updateDoc(winRef, {
        claimed: true,
        claimedDate: claimedTimestamp,
        documentID: win.id, // Included for tech support tracking
      });

      // Update local state to reflect changes
      setWins(
        wins.map((w) =>
          w.id === win.id
            ? { ...w, claimed: true, claimedDate: claimedTimestamp.toDate() }
            : w
        )
      );

      // Display an alert message about the payment process
      alert(
        "Your payment is being processed, you will receive payment within 24 hours."
      );

      console.log(
        `Claimed win updated in ${collectionName} with ID: ${win.id}`
      );
    } catch (error) {
      console.error("Error claiming win:", error);
      alert("Failed to claim the win. Please try again later.");
    }
  };

  if (!currentUser) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  return (
    <Container className="mt-5">
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title className="text-center">
                Welcome, {currentUser.displayName}
              </Card.Title>
              {todayWinner && (
                <Card.Text className="text-center">
                  {currentUser.uid === todayWinner.userId
                    ? `Congratulations, ${currentUser.displayName}! You won today's ${todayWinner.amount} cedis Kenkey Gift prize.`
                    : `Today's winner is ${todayWinner.name} who has won ${todayWinner.amount} cedis. Congratulations! We wish you ${currentUser.displayName} good luck winning next round.`}
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Your Wins</Card.Title>
              <div className="table-responsive">
                <Table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Winning ID</th>
                      <th>Amount</th>
                      <th>Win Type</th>
                      <th>Claim Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wins.map((win) => (
                      <tr key={win.id}>
                        <td>{formatDate(win.date.seconds)}</td>
                        <td>{win.id}</td>
                        <td>{win.amount} cedis</td>
                        <td>{win.type}</td>
                        <td>{win.claimed ? "Claimed" : "Not Claimed"}</td>
                        <td>
                          <Button
                            variant="primary"
                            disabled={win.claimed}
                            onClick={() => handleClaimWin(win)}
                          >
                            {win.claimed ? "Claimed" : "Claim"}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <Card.Text className="mt-3">
                Total Wins: <strong>{totalWins} cedis</strong>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <p>
                Earn referral income by having friends and family also
                participate in Kenkey Gift Prize and enjoy future
                allghanaians.com services coming soon!!!
              </p>
              <Card.Title>Your Referrals</Card.Title>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Referral Date</th>
                    <th>Signed Up</th>
                    <th>Signed Date</th>
                    <th>Earned Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {referrals.map((referral) => (
                    <tr key={referral.id}>
                      <td>{referral.displayName}</td>
                      <td>{referral.referralDate}</td>
                      <td>{referral.signedUp}</td>
                      <td>{referral.signedUpDate}</td>
                      <td>{referral.earnedAmount} cedis</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WinnerOverviews;
