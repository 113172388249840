import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { projectAuth } from "../../firebase/config";
import {
  Navbar,
  Nav,
  FormControl,
  Button,
  Dropdown,
  Container,
} from "react-bootstrap";
import { UserContext } from "../contexts/UserContext";
import { BsSearch, BsPerson, BsCart } from "react-icons/bs";
import logoImage1 from "../../assets/allgLogo2.png";

import styles from "./Header.module.css";

const Header = () => {
  // const { currentUser } = useContext(UserContext);
  const { currentUser, setCurrentUser, loading, isAdmin } =
    useContext(UserContext); // Destructure isAdmin from the context
  // const { currentUser, setCurrentUser, loading } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  //console.log("Header component, currentUser:", currentUser);

  
  const handleLogout = async () => {
    try {
      await signOut(projectAuth);
      //console.log('User signed out from Firebase');
      setCurrentUser(null); // Update the currentUser in the context
      //console.log('currentUser should be null now:', currentUser);
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      className={styles.header}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className={styles.logoContainer}>
          <img src={logoImage1} alt="Logo" className={styles.logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={styles.headerNavbar}>
            <Nav.Link as={Link} to="/" className={styles.navLink}>
              Home
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/all-services" className={styles.navLink}>
              Services
            </Nav.Link> */}
            <Nav.Link as={Link} to="/policies" className={styles.navLink}>
              Our Policy
            </Nav.Link>
            <Nav.Link as={Link} to="/contacts" className={styles.navLink}>
              Contact
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/faqs" className={styles.navLink}>
              FAQs
            </Nav.Link> */}
            <Nav.Link as={Link} to="/dashboard" className={styles.navLink}>
              Dashboard
            </Nav.Link>

           

            {/* Search Form */}
            <form onSubmit={handleSearchSubmit} className={styles.searchForm}>
              <FormControl
                type="text"
                placeholder="Search"
                className={styles.searchInput}
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
              <Button
                variant="outline-primary"
                type="submit"
                className={styles.searchButton}
              >
                <BsSearch />
              </Button>
            </form>

            {/* Conditional Rendering for User Info, Logout, Login, Signup */}
            <div className={`d-flex align-items-center ${styles.authLinks}`}>
              {!loading && currentUser ? (
                // User Info and Logout Dropdown
                <Dropdown align="end" className={`mx-2 ${styles.dropdown}`}>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className={styles.dropdownToggle}
                  >
                    {currentUser.displayName || "User"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Header>{currentUser.email}</Dropdown.Header>
                    <Dropdown.ItemText>
                      Role: {currentUser.role || "User"}
                    </Dropdown.ItemText>
                    <Dropdown.ItemText>
                      UserId: {currentUser.uid || "Unknown"}
                    </Dropdown.ItemText>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                // Signup and Login links for unauthenticated users
                <>
                  <Nav.Link as={Link} to="/signup" className={styles.loginLink}>
                    SignUp
                  </Nav.Link>
                  <Nav.Link as={Link} to="/login" className={styles.loginLink}>
                    Login
                  </Nav.Link>
                </>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
