// import React, { useState, useEffect } from "react";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   Timestamp,
// } from "firebase/firestore";
// import { projectFirestore } from "../../firebase/config";
// import { Table } from "react-bootstrap";

// const AdminClaimedWins = () => {
//   const [claimedWins, setClaimedWins] = useState([]);

//   useEffect(() => {
//     const fetchClaimedWins = async () => {
//       const now = new Date();
//       const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);

//       const claimedQuery = query(
//         collection(projectFirestore, "dailyWinners"),
//         where("claimed", "==", true),
//         where("claimedDate", ">=", Timestamp.fromDate(yesterday))
//       );

//       const snapshot = await getDocs(claimedQuery);
//       const wins = snapshot.docs.map((doc) => ({
//         userId: doc.data().userId,
//         winId: doc.id,
//         amount: doc.data().amount,
//         winType: doc.data().type,
//         claimedStatus: doc.data().claimed ? "Claimed" : "Unclaimed",
//       }));

//       setClaimedWins(wins);
//     };

//     fetchClaimedWins();
//   }, []);

//   return (
//     <div>
//       <h1>Claimed Wins in Last 24 Hours</h1>
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th>User ID</th>
//             <th>Winning ID</th>
//             <th>Amount</th>
//             <th>Win Type</th>
//             <th>Claimed Status</th>
//           </tr>
//         </thead>
//         <tbody>
//           {claimedWins.map((win) => (
//             <tr key={win.winId}>
//               <td>{win.userId}</td>
//               <td>{win.winId}</td>
//               <td>{win.amount}</td>
//               <td>{win.winType}</td>
//               <td>{win.claimedStatus}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default AdminClaimedWins;

// import React, { useState, useEffect } from "react";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   Timestamp,
//   doc,
//   getDoc,
// } from "firebase/firestore";
// import { projectFirestore } from "../../firebase/config";
// import { Table } from "react-bootstrap";

// const AdminClaimedWins = () => {
//   const [claimedWins, setClaimedWins] = useState([]);

//   useEffect(() => {
//     const fetchClaimedWins = async () => {
//       const now = new Date();
//       const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);

//       const claimedQuery = query(
//         collection(projectFirestore, "dailyWinners"),
//         where("claimed", "==", true),
//         where("claimedDate", ">=", Timestamp.fromDate(yesterday))
//       );

//       const snapshot = await getDocs(claimedQuery);
//       const wins = await Promise.all(
//         snapshot.docs.map(async (docSnapshot) => {
//           const userRef = doc(
//             projectFirestore,
//             "users",
//             docSnapshot.data().userId
//           );
//           const userDataSnapshot = await getDoc(userRef);
//           const userData = userDataSnapshot.exists()
//             ? userDataSnapshot.data()
//             : null;
//           return {
//             userId: docSnapshot.data().userId,
//             winId: docSnapshot.id,
//             amount: docSnapshot.data().amount,
//             winType: docSnapshot.data().type,
//             claimedStatus: docSnapshot.data().claimed ? "Claimed" : "Unclaimed",
//             userName: userData ? userData.name : "Unknown User",
//             claimedDate: docSnapshot
//               .data()
//               .claimedDate.toDate()
//               .toLocaleDateString(),
//             paidStatus: docSnapshot.data().paid ? "Paid" : "Not Paid",
//           };
//         })
//       );

//       setClaimedWins(wins);
//     };

//     fetchClaimedWins();
//   }, []);

//   return (
//     <div>
//       <h1>Claimed Wins in Last 24 Hours</h1>
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th>User ID</th>
//             <th>User Name</th>
//             <th>Winning ID</th>
//             <th>Amount</th>
//             <th>Win Type</th>
//             <th>Claimed Date</th>
//             <th>Claimed Status</th>
//             <th>Paid Status</th>
//           </tr>
//         </thead>
//         <tbody>
//           {claimedWins.map((win) => (
//             <tr key={win.winId}>
//               <td>{win.userId}</td>
//               <td>{win.name}</td>
//               <td>{win.winId}</td>
//               <td>{win.amount}</td>
//               <td>{win.winType}</td>
//               <td>{win.claimedDate}</td>
//               <td>{win.claimedStatus}</td>
//               <td>{win.paidStatus}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default AdminClaimedWins;

import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { projectFirestore } from "../../firebase/config";
import { Table } from "react-bootstrap";

const AdminClaimedWins = () => {
  const [claimedWins, setClaimedWins] = useState([]);

  useEffect(() => {
    const fetchClaimedWins = async () => {
      const now = new Date();
      const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);

      const claimedQuery = query(
        collection(projectFirestore, "dailyWinners"),
        where("claimed", "==", true),
        where("claimedDate", ">=", Timestamp.fromDate(yesterday))
      );

      const snapshot = await getDocs(claimedQuery);
      const wins = snapshot.docs.map((docSnapshot) => ({
        userId: docSnapshot.data().userId,
        winId: docSnapshot.id,
        amount: docSnapshot.data().amount,
        winType: docSnapshot.data().type,
        claimedStatus: docSnapshot.data().claimed ? "Claimed" : "Unclaimed",
        userName: docSnapshot.data().name, // Using the name directly from the document
        claimedDate: docSnapshot
          .data()
          .claimedDate.toDate()
          .toLocaleDateString(),
        paidStatus: docSnapshot.data().paid,
      }));

      setClaimedWins(wins);
    };

    fetchClaimedWins();
  }, []);

  return (
    <div>
      <h1>Claimed Wins in Last 24 Hours</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>User ID</th>
            <th>User Name</th>
            <th>Winning ID</th>
            <th>Amount</th>
            <th>Win Type</th>
            <th>Claimed Date</th>
            <th>Claimed Status</th>
            <th>Paid Status</th>
          </tr>
        </thead>
        <tbody>
          {claimedWins.map((win) => (
            <tr key={win.winId}>
              <td>{win.userId}</td>
              <td>{win.userName}</td>
              <td>{win.winId}</td>
              <td>{win.amount}</td>
              <td>{win.winType}</td>
              <td>{win.claimedDate}</td>
              <td>{win.claimedStatus}</td>
              <td>{win.paidStatus}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminClaimedWins;
