import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot } from "firebase/firestore";
import { projectFirestore } from "../../firebase/config";

const MonitoringDashboard = () => {
  const [winners, setWinners] = useState([]);
  const [failedPayments, setFailedPayments] = useState([]);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchWinners = () => {
      const q = query(collection(projectFirestore, "dailyWinners"));
      onSnapshot(q, (snapshot) => {
        const winnersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWinners(winnersData);
      });
    };

    const fetchFailedPayments = () => {
      const q = query(collection(projectFirestore, "failedPayments"));
      onSnapshot(q, (snapshot) => {
        const paymentsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFailedPayments(paymentsData);
      });
    };

    const fetchNotifications = () => {
      const q = query(collection(projectFirestore, "notifications"));
      onSnapshot(q, (snapshot) => {
        const notificationsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotifications(notificationsData);
      });
    };

    fetchWinners();
    fetchFailedPayments();
    fetchNotifications();
  }, []);

  return (
    <div>
      <h1>Payment Monitoring Dashboard</h1>

      <h2>Winners List</h2>
      <ul>
        {winners.map((winner) => (
          <li key={winner.id}>
            {winner.name} - {winner.amount} GHS - Status: {winner.paid}
          </li>
        ))}
      </ul>

      <h2>Failed Payments</h2>
      <ul>
        {failedPayments.map((payment) => (
          <li key={payment.id}>
            Winner ID: {payment.winnerId} - Reason: {payment.reason} - Retry
            Count: {payment.retryCount}
          </li>
        ))}
      </ul>

      <h2>Admin Notifications</h2>
      <ul>
        {notifications.map((note) => (
          <li key={note.id}>
            {note.message} - {new Date(note.timestamp).toLocaleString()}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MonitoringDashboard;
