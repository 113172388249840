import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { projectFirestore } from "../../firebase/config";
import { Container, Table, Button, Form, Card } from "react-bootstrap";

const BalanceManager = () => {
  const [balance, setBalance] = useState(null);
  const [amount, setAmount] = useState("");
  const [initialBalance, setInitialBalance] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const balanceDocRef = doc(projectFirestore, "systemSettings", "balance");

  // Fetch balance on component mount
  useEffect(() => {
    const fetchBalance = async () => {
      const docSnap = await getDoc(balanceDocRef);
      if (docSnap.exists()) {
        setBalance(docSnap.data());
      } else {
        console.log("Balance document not found");
      }
    };
    fetchBalance();
  }, []);

  // Create or Initialize Balance
  const createBalance = async () => {
    await setDoc(balanceDocRef, {
      totalBalance: parseFloat(initialBalance),
      lastUpdated: serverTimestamp(),
    });
    setBalance({
      totalBalance: parseFloat(initialBalance),
      lastUpdated: new Date(),
    });
    setInitialBalance("");
  };

  // Update Balance by Incrementing/Decrementing
  const updateBalance = async () => {
    await updateDoc(balanceDocRef, {
      totalBalance: balance.totalBalance + parseFloat(amount),
      lastUpdated: serverTimestamp(),
    });
    setBalance({
      ...balance,
      totalBalance: balance.totalBalance + parseFloat(amount),
      lastUpdated: new Date(),
    });
    setAmount("");
  };

  // Delete Balance Document
  const deleteBalance = async () => {
    await deleteDoc(balanceDocRef);
    setBalance(null);
  };

  return (
    <Container className="mt-4">
      <h2 className="text-center">Balance Manager</h2>

      {/* Table to Display Balance */}
      {balance ? (
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>Total Balance</th>
              <th>Last Updated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{balance.totalBalance}</td>
              <td>
                {balance.lastUpdated
                  ? balance.lastUpdated.toDate().toDateString()
                  : "N/A"}
              </td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => setIsEditing(true)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button variant="danger" onClick={deleteBalance}>
                  Delete
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <p className="text-center mt-4">
          No balance found. Please create a new balance.
        </p>
      )}

      {/* Form for Creating/Updating Balance */}
      <Card className="p-3 mt-4">
        <h4>{balance ? "Update Balance" : "Create Balance"}</h4>
        <Form>
          {balance ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Amount to Add/Subtract</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                onClick={updateBalance}
                className="w-100"
              >
                Update Balance
              </Button>
            </>
          ) : (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Initial Balance</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter initial balance"
                  value={initialBalance}
                  onChange={(e) => setInitialBalance(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                onClick={createBalance}
                className="w-100"
              >
                Create Balance
              </Button>
            </>
          )}
        </Form>
      </Card>
    </Container>
  );
};

export default BalanceManager;
